import { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { images } from "../constants";

const NavList = [
  {
    name: "Home",
    path: "/",
    type: "single",
  },
  {
    name: "Blog",
    path: "/",
    type: "single",
  },
  {
    name: "Pages",
    path: "/",
    type: "dropdown",
    pages: ["Portfolio", "Projects"],
  },
  {
    name: "About",
    path: "/",
    type: "single",
  },
  {
    name: "Contact",
    path: "/",
    type: "single",
  },
];

const MenuItem = ({ item }) => {
  return (
    <li className="relative group">
      {item.type === "single" ? (
        <>
          <a href={item.path} className="px-4 py-2">
            {item.name}
          </a>
          <span className="absolute transition-all duration-500 font-bold right-0 top-0 group-hover:right-[90%] opacity-0 group-hover:opacity-100">
            /
          </span>
        </>
      ) : (
        <>
          <a href={item.path} className="px-4 py-2 flex gap-x-1 items-center">
            {item.name}
            <MdKeyboardArrowDown />
          </a>
          <span className="absolute transition-all duration-500 font-bold right-0 top-0 group-hover:right-[90%] opacity-0 group-hover:opacity-100">
            /
          </span>
          <div className="hidden transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full w-max group-hover:block">
            <ul className="flex flex-col shadow-lg rounded-lg overflow-hidden">
              {item.pages.map((page) => (
                <li>
                  <a
                    href="/"
                    className="hover:bg-dark-hard hover:text-white px-4 py-2 text-white lg:text-dark-soft"
                  >
                    {page}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </li>
  );
};

const Header = () => {
  const [navVisibility, setNavVisibility] = useState(false);

  const navVisibilityHandler = () => {
    setNavVisibility((curState) => {
      return !curState;
    });
  };
  return (
    <nav>
      <header className="container mx-auto px-5 flex justify-between py-4 items-center">
        <div>
          <img className="w-64" src={images.Logo} alt="Logo" />
        </div>
        <div className="lg:hidden z-50">
          {navVisibility ? (
            <AiOutlineClose
              className="w-6 h-6"
              onClick={navVisibilityHandler}
            />
          ) : (
            <AiOutlineMenu className="w-6 h-6" onClick={navVisibilityHandler} />
          )}
        </div>
        <div
          className={`${
            navVisibility ? "right-0" : "-right-full"
          } transition-all duration-500 mt-[56px] lg:mt-0 bg-dark-hard lg:bg-transparent z-[49] fixed justify-center lg:justify-end w-full lg:w-auto top-0 bottom-0  lg:static flex flex-col lg:flex-row gap-x-9 items-center`}
        >
          <ul className="text-white items-center gap-y-5 lg:text-dark-soft flex flex-col lg:flex-row gap-x-5 font-semibold">
            {NavList.map((item) => (
              <MenuItem key={item.name} item={item} />
            ))}
          </ul>
          <button className="mt-5 lg:mt-0 border-2 border-black px-6 py-2 rounded-full font-semibold hover:bg-black hover:text-white transition-all duration-300">
            Sign In
          </button>
        </div>
      </header>
    </nav>
  );
};

export default Header;
