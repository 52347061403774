import "./App.css";
import MainLayout from "./components/MainLayout";

function App() {
  return (
    <>
      <MainLayout />
    </>
  );
}

export default App;
